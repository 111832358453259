import React, { useState, useEffect } from 'react';
import DialogBox from './Components/DialogBox';

// Handle clicks on images set as clickable thumbnails. Would normally add an event handler in TextBlockMedia but its
// elements are recreated by SectionContent so we can't add event handlers there.
const ClickableThumbnails: React.FC = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [url, setURL] = useState<string | null>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;
      if (!target.matches('.clickable-thumbnail')) {
        return;
      }

      const src = target.dataset.src;
      if (!src) {
        return;
      }

      setURL(src);
      setModal(true);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  if (!modal) {
    return <></>;
  }

  return (
    <DialogBox
      size="lg"
      open={modal}
      toggle={setModal}
      header=" "
      content={(
        <img
          src={url || ''}
          alt="Enlarged thumbnail"
          style={{ maxWidth: '100%', maxHeight: '80vh' }}
        />
      )}
      className="text-center"
    />
  );
};

export default ClickableThumbnails;
