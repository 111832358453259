import React, { useEffect, useState } from 'react';
import { FrameBox } from './Sections/ImageGallery/Popup';
import { useAppSelector } from '../rootStore';

// Utility function for slide toggle with TypeScript
const slideToggle = (element: HTMLElement, duration = 400): void => {
  if (!element) return;

  const isHidden = window.getComputedStyle(element).display === 'none';

  let displayProperty;

  // Would be great if we could determine the original display property of the element but I (MP) can't figure out how to do that. These need to match the display properties in show_style.css.erb.
  if (element.matches('.SectionColumn.layout-grid')) {
    displayProperty = 'grid';
  } else if (element.matches('.SectionColumn')) {
    displayProperty = 'flex';
  } else {
    displayProperty = 'block';
  }

  if (isHidden) {
    // Show element
    element.style.display = displayProperty;
    const height = element.scrollHeight; // Get full height
    element.style.height = '0';
    element.style.overflow = 'hidden';
    element.style.transition = ` height ${duration}ms ease-in-out`;

    requestAnimationFrame(() => {
      element.style.height = ` ${height}px`;
    });

    setTimeout(() => {
      element.style.height = '';
      element.style.overflow = '';
      element.style.transition = '';
    }, duration);
  } else {
    // Hide element
    const height = element.offsetHeight; // Get current height
    element.style.height = `${height}px`;
    element.style.overflow = 'hidden';
    element.style.transition = ` height ${duration}ms ease-in-out`;

    requestAnimationFrame(() => {
      element.style.height = '0';
    });

    setTimeout(() => {
      element.style.display = 'none';
      element.style.height = '';
      element.style.overflow = '';
      element.style.transition = '';
    }, duration);
  }
};

const LinkClickListener: React.FC = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const clickedSection = useAppSelector((state) => state.clickedSection?.clicked_section);

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      const a = (e.target as HTMLElement).closest<HTMLAnchorElement>('a');
      if (!a) {
        return;
      }

      if (a.matches('.showModal')) {
        e.preventDefault();

        const url = new URL(a.href);
        url.searchParams.append('wgpopup', '1');
        setUrl(url.toString());

        setModal(true);
      } else if (a.matches('.toggleVisibility')) {
        const originId = a.dataset.originId;
        if (!originId) {
          return;
        }
        e.preventDefault();

        // Close other sections if close_other_sections is set
        const targetSections = document.querySelectorAll<HTMLElement>(`.origin${originId}`);
        const targetSectionIsVisible = [...targetSections].some((targetSection) => window.getComputedStyle(targetSection).display !== 'none');

        if (a.dataset.closeOtherSections && !targetSectionIsVisible) {
          const otherLinks = document.querySelectorAll<HTMLElement>('.toggleVisibility');

          const otherTargetSections = [] as HTMLElement[];
          otherLinks.forEach((otherLink) => {
            if (otherLink.dataset.originId !== originId) {
              const otherTargetSection = document.querySelector<HTMLElement>(`.origin${otherLink.dataset.originId}`);
              if (otherTargetSection && window.getComputedStyle(otherTargetSection).display !== 'none') {
                otherTargetSections.push(otherTargetSection);
              }
            }
          });

          otherTargetSections.forEach((otherTargetSection) => {
            slideToggle(otherTargetSection);
          });
        }

        targetSections.forEach((targetSection) => {
          slideToggle(targetSection);
          if (!targetSection && clickedSection) {
            return;
          }
          if ((targetSection as any).scrollIntoViewIfNeeded) {
            (targetSection as any).scrollIntoViewIfNeeded();
          } else {
            targetSection.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
          }
        });
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return modal
    ? (
        <FrameBox
          modal={modal}
          url={url}
          toggle={(e: boolean) => {
            setModal(e);
            setUrl('');
          }}
        />
      )
    : null;
};

export default LinkClickListener;
