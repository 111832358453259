import React from 'react';
import { createStore, useAppSelector } from 'rootStore';
import { get, isEmpty } from 'lodash-es';
import { BlogPostType, socialMedia } from 'common/models/Site';
import { PageDataObject } from 'ts/interfaces';

type SocialMediaIconsProps = {
  post: BlogPostType;
};

function SocialMediaIcons({ post }: SocialMediaIconsProps) {
  const site = useAppSelector((state: PageDataObject) => state.pageData.site);
  const blogSocialMedia = get(site, 'blog_social_media', []);

  if (isEmpty(post) || isEmpty(blogSocialMedia)) {
    return null;
  }

  return (
    <div>
      <div className="d-flex gap-2 mt-2">
        {blogSocialMedia.map((platform) => {
          const social = socialMedia.find((s) => s.value === platform);
          if (!social) return null;

          const shareUrl = social.url(post);

          return (
            <a
              key={platform}
              href={shareUrl}
              target="_blank"
              className="me-2"
              rel="noreferrer"
            >
              <i className={`fa-brands fa-${social.icon}`} />
            </a>
          );
        })}
      </div>
    </div>
  );
};

function BlogPostLayout1({ where }: { where: string }) {
  const store = createStore();
  const {
    pageData: {
      site,
      page,
      blog_posts,
    },
  } = store.getState();
  const images = useAppSelector((state: PageDataObject) => state.pageData.file);

  const publishDate = (new Date(page.blog_publish_date || '')).toDateString();

  if (where === 'top') {
    return (
      <>
        <style>
          {`
            .blog-post-image {
              display: block;
              margin: 1rem 0;
              max-width: 100%;
              max-height: 700px;
            }
          `}
        </style>
        <h2
          className={`style-${site.blog_post_title_style_id}`}
        >
          {page.title}
        </h2>
        <div className={`style-${site.blog_body_style_id}`}>
          {page.blog_author}
          {!isEmpty(page.blog_author) && ' | '}
          {publishDate}
          <br />
          {page.blog_post_image_url && (
            <img
              className="blog-post-image"
              src={page.blog_post_image_url}
              alt={page.title || ''}
            />
          )}
        </div>
      </>
    );
  }

  if (where === 'bottom') {
    return (
      <>
        <style>
          {`
            .blog-posts-container {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 1rem;
            }
          `}
        </style>
        <div className={`style-${site.blog_body_style_id}`}>
          <br />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {page.blog_tags.map((tag) => (
              <a
                key={tag}
                href={`${site.blog_page_url}?tag=${encodeURIComponent(tag)}`}
                className="me-2"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  fontSize: '0.75rem',
                  border: '1px solid var(--primary)',
                  borderRadius: '1rem',
                  padding: '0.25rem 1rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {tag}
              </a>
            ))}
          </div>
          <br />
          <strong>
            Share This:
          </strong>
          <SocialMediaIcons post={page} />
          <hr />
          <strong>
            More Blog Posts:
          </strong>
          <br />
          <br />
          <div className="blog-posts-container">
            {blog_posts.map((post: BlogPostType) => {
              const image = images.find((img) => img.id === post.blog_post_image_id);
              const publishDate = (new Date(post.blog_publish_date || '')).toDateString();

              return (
                <div key={post.id}>
                  <a
                    href={post.url}
                  >
                    {post.blog_post_image_url && (
                      <img
                        src={post.blog_post_image_url}
                        alt={post.title}
                        className="pb-2"
                        style={{
                          aspectRatio: image ? `${image.width}/${image.height}` : 'auto',
                          maxWidth: '100%',
                        }}
                      />
                    )}
                    {post.title}
                  </a>
                  <br />
                  {post.blog_author}
                  {!isEmpty(post.blog_author) && ' | '}
                  {publishDate}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default BlogPostLayout1;
