import React, { useEffect } from 'react';
import { useAppSelector } from '../rootStore';
import { PageDataObject } from '../ts/interfaces';
import { BlogPostType } from 'common/models/Site';
import MiniMasonry from 'minimasonry';

function BlogPost({ post }: { post: BlogPostType }) {
  const site = useAppSelector((state: PageDataObject) => state.pageData.site);
  const images = useAppSelector((state: PageDataObject) => state.pageData.file);
  const image = images.find((img) => img.id === post.blog_post_image_id);

  const date = post.blog_publish_date ? new Date(post.blog_publish_date) : null;

  return (
    <div
      key={post.id}
      className="blog-post"
    >
      <a href={post.url}>
        {date && (
          <div className="date">
            <span className="month">{date.toLocaleString('en-US', { month: 'short' })}</span>
            <br />
            <span className="day">{date.getDate()}</span>
            <br />
            <span className="year">{date.getFullYear()}</span>
          </div>
        )}
        <div className={`style-${site.blog_body_style_id}`}>
          {post.blog_post_image_url && (
            <img
              src={post.blog_post_image_url}
              alt={post.title}
              style={{
                aspectRatio: image ? `${image.width}/${image.height}` : 'auto',
              }}
            />
          )}
          {!post.blog_post_image_url && (
            <div className="image-placeholder" />
          )}
          <div className="text-container">
            <h2 className={`style-${site.blog_post_title_style_id}`}>
              {post.title}
            </h2>
            <p>{post.blog_excerpt}</p>
            <p>
              Read more
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}

function BlogListLayoutMasonry() {
  const site = useAppSelector((state: PageDataObject) => state.pageData.site);
  const device = useAppSelector((state) => state.device?.device);
  const blogPosts = useAppSelector((state: PageDataObject) => state.pageData.blog_posts);

  const imageListener = () => {
    if (device === 'phone') {
      // We just display a single column on phones, don't need masonry.
      return;
    }

    const container = document.querySelector('.blog-post-list');

    if (!container) {
      return;
    }

    new MiniMasonry({
      container,
      baseWidth: device === 'tablet' ? 300 : 400,
      gutter: 24,
      surroundingGutter: false,
    });
  };

  useEffect(() => {
    imageListener();
  }, [device]);

  // Recalculate the masonry layout when images are loaded.
  useEffect(() => {
    document.addEventListener('load', (event) => {
      if (event?.target instanceof HTMLElement && event.target.tagName === 'IMG') {
        imageListener();
      }
    }, true);
  }, []);

  const params = new URLSearchParams(location.search);
  const tag = params.get('tag');

  return (
    <>
      <style>
        {`
          .blog-post-list {
            position: relative;
          }

          .blog-post {
            position: relative;
            max-width: 100%;
            margin-bottom: 24px; /* same as gutter */
            background-color: var(--white);

            a:link { text-decoration: none !important; }

            .text-container {
              padding: 1.5rem;
            }

            h2 {
              text-transform: uppercase;
            }

            .date {
              position: absolute;
              top: 0;
              left: 2rem;
              background-color: var(--secondary);
              text-align: center;
              padding: 0.4rem 1rem;
              color: var(--white);
              font-weight: bold;
              line-height: 1;
              text-transform: uppercase;

              .month, .year {
                font-size: 0.75rem;
              }
              .day {
                font-size: 2rem;
              }
            }

            img {
              display: block;
              max-width: 100%;
              max-height: 400px;
              margin: 0 auto;
            }

            .image-placeholder {
              width: 100%;
              height: 70px;
            }
          }
          .device-tablet, .device-desktop {
            .blog-post {
              position: absolute;
            }
          }
        `}
      </style>
      <div className="action-page">
        <div className="blog-post-list-container">
          {tag && (
            <h1 className={`style-${site.blog_post_title_style_id}`}>
              Posts tagged with &quot;
              {tag}
              &quot;
            </h1>
          )}
          <div className="blog-post-list">
            {blogPosts.map((post) => <BlogPost key={post.id} post={post as BlogPostType} />)}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogListLayoutMasonry;
