import React, { useEffect, useState } from 'react';
import DialogBox from './Components/DialogBox/index';

const InstantContactButton: React.FC = () => {
  if (!window.site?.instantContactForm || !window.site.instantContactForm.display) {
    return null;
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [displayBackButton, setDisplayBackButton] = useState(false);

  // We need to listen for a message from the iframe to know when to display the back button.
  useEffect(() => {
    const messageHandler = (e: MessageEvent) => {
      if (e.data.type === 'instantContactInterstitialNavigated') {
        setDisplayBackButton(true);
      }
    };

    window.addEventListener('message', messageHandler);

    setTimeout(() => {
      const button = document.querySelector<HTMLElement>('.instant-contact-button');
      if (!button) {
        return;
      }

      button.style.display = 'block';

      const buttonWidth = button.clientWidth;
      if (buttonWidth) {
        button.style.marginTop = `${-(buttonWidth / 2)}px`;
      }

      // Add some margin at the bottom of the <body> so that the last bit of content in the body doesn't get hidden
      // behind the instant contact button. We are doing this on an interval just in case the first time it runs
      // the button is not displayed yet or the height hasn't been computed, or maybe the <html> element doesn't
      // have .device-phone yet.
      const addBodyPadding = () => {
        if (document.documentElement.classList.contains('device-phone')) {
          document.body.style.marginBottom = `${button.clientHeight}px`;
        }
      };

      addBodyPadding();
      const intervalId = setInterval(addBodyPadding, 1000);

      return () => {
        clearInterval(intervalId);
        window.removeEventListener('message', messageHandler);
      };
    }, 100);
  }, []);

  const url = new URL(window.site.instantContactForm.popupUrl, window.location.origin);
  url.searchParams.append('icf', '1');

  const toggle = (state: boolean): void => {
    setModalOpen(state);
  };

  const handleBackClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    window.history.back();
    setDisplayBackButton(false);
  };

  // Custom dialog header content with back button
  const headerContent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <a
        style={{
          visibility: displayBackButton ? 'visible' : 'hidden',
          marginRight: '10px',
        }}
        href="#"
        onClick={handleBackClick}
      >
        <i className="fa fal fa-arrow-left" />
      </a>
    </div>
  );

  // Main content for the dialog
  const dialogContent = (
    <iframe
      data-frameid={crypto.getRandomValues(new Uint32Array(1))[0].toString()}
      style={{ width: '100%', border: 'none', minHeight: '400px' }}
      src={url.toString()}
    />
  );

  return (
    <>
      <DialogBox
        open={modalOpen}
        toggle={toggle}
        content={dialogContent}
        header={headerContent}
        size="lg"
        className="instant-contact-dialog"
        footerButtons={[]}
      />

      <a
        href="#"
        id={window.site.instantContactForm.pageId}
        data-id={window.site.instantContactForm.pageId}
        data-header={window.site.instantContactForm.text}
        className="instant-contact-button"
        onClick={(e) => {
          e.preventDefault();
          setModalOpen(true);
        }}
      >
        <span className={`style-${window.site.instantContactForm.styleId}`}>
          {window.site.instantContactForm.text}
        </span>
      </a>
    </>
  );
};

export default InstantContactButton;
