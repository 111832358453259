import React from 'react';
import { useSelector } from 'react-redux';
import { useFireRenderEvent } from '../common/hooks';
import TemplateGrid from './TemplateGrid';
import TemplateHTML from './TemplateHTML';
import { RootState } from 'ts/interfaces';

const PageLayout: React.FC = () => {
  // Any custom code that refers to elements in the DOM may have problems because if the page re-renders, the elements
  // won't exist any more. We fire the "render" event that custom code can listen to so that it runs every time the page
  // renders.
  useFireRenderEvent();

  const template = useSelector((state: RootState) => state.pageData.template);

  if (!template) {
    return <></>;
  }

  if (template.grid) {
    return <TemplateGrid template={template} />;
  }
  return <TemplateHTML template={template} />;
};

export default PageLayout;
