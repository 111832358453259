import React from 'react';
import { useAppSelector } from '../rootStore';
import { PageDataObject } from '../ts/interfaces';
import BlogListLayoutMasonry from './BlogListLayoutMasonry';
import BlogListLayoutOneColWithSidebar from './BlogListLayoutOneColWithSidebar';

function Blog() {
  const site = useAppSelector((state: PageDataObject) => state.pageData.site);

  if (!site.blog_list_layout) {
    return <BlogListLayoutMasonry />;
  }

  const layoutMap = {
    masonry: BlogListLayoutMasonry,
    one_col_with_sidebar: BlogListLayoutOneColWithSidebar,
  };

  const BlogListLayout = layoutMap[site.blog_list_layout];

  return <BlogListLayout />;
}

export default Blog;
