import React, { useEffect, useState } from 'react';
import { getCookie } from '../Utils';
import { createStore } from '../rootStore';
import DialogBox from './Components/DialogBox';
import { RootState } from 'ts/interfaces';
import Site from 'common/models/Site';
import Page from 'common/models/Page';

const Popup: React.FC = () => {
  const [modal, setModal] = useState<boolean>(false);

  const store = createStore();
  const { pageData: { site, page, member } } = store.getState() as RootState;

  // page popup should be given preference over site popup
  const popupObject: Page | Site = page.popup_page_id ? page : site;

  const {
    popup_visible_to: popupVisibleTo,
  } = popupObject;

  const {
    frequency = 'every_visit',
    frame = true,
    trigger = 'delay',
    trigger_value: triggerValue,
    popup_url: popupUrl,
  } = (popupObject.popup_options || {});

  let popupShown = false;
  const showPopup = (): void => {
    if (popupShown) {
      return;
    }
    setModal(true);
    if (frequency === 'once_per_visitor') {
      document.cookie = `popupSeen=1; max-age=${86400 * 365 * 10}; path=/;`;
    } else if (!frequency || frequency === 'every_visit') {
      document.cookie = `popupSeen=1; max-age=86400; path=/;`;
    }
    popupShown = true;
  };

  useEffect(() => {
    if (location.search.match(/wgpopup/)) {
      // We are inside a popup, need to send the height to the parent.
      const setIframeHeight = (): void => top?.postMessage({
        iframeLocation: window.location.href,
        iframeHeight: document.body.clientHeight,
      }, '*');

      setIframeHeight();

      // And run it every so often just in case things inside the iframe get resized.
      const intervalId = setInterval(setIframeHeight, 250);
      return () => clearInterval(intervalId);
    }

    const popupSeen = getCookie('popupSeen');

    if (popupSeen) {
      return;
    }

    let scrollListener: EventListener | null = null;
    let mouseoutListener: EventListener | null = null;

    switch (trigger) {
      case 'delay': {
        const seconds = typeof triggerValue === 'number' ? triggerValue : parseInt(triggerValue || '0', 10);
        const timeoutId = setTimeout(showPopup, seconds * 1000);
        return () => clearTimeout(timeoutId);
      }
      case 'scroll_percentage': {
        const triggerPercentage = typeof triggerValue === 'number' ? triggerValue : parseInt(triggerValue || '0', 10);
        if (triggerPercentage > 0) {
          scrollListener = () => {
            const scrolledPercentage
              = (window.scrollY / document.body.scrollHeight) * 100;
            if (scrolledPercentage > triggerPercentage) {
              showPopup();
            }
          };
          document.addEventListener('scroll', scrollListener);
        }
        break;
      }
      case 'exit_intent':
        mouseoutListener = (e: Event) => {
          const mouseEvent = e as MouseEvent;
          if (!mouseEvent.relatedTarget) {
            showPopup();
          }
        };
        document.addEventListener('mouseout', mouseoutListener);
        break;
      default:
        showPopup();
    }

    return () => {
      if (scrollListener) {
        document.removeEventListener('scroll', scrollListener);
      }
      if (mouseoutListener) {
        document.removeEventListener('mouseout', mouseoutListener);
      }
    };
  }, [trigger, triggerValue]);

  if (!popupUrl) {
    return null;
  }

  if (popupVisibleTo === 'all_database_members' && !member) {
    return null;
  }

  if (popupVisibleTo === 'those_not_on_your_database' && member) {
    return null;
  }

  // Don't want to show a popup inside a popup
  if (typeof window !== 'undefined' && window !== window.top) {
    return null;
  }

  return (
    <DialogBox
      size="lg"
      open={modal}
      toggle={setModal}
      content={(
        <iframe
          id="popup"
          src={`${popupUrl}?wgpopup`}
          style={{ width: '100%', maxHeight: '70vh' }}
        />
      )}
      className={frame ? '' : 'no-frame'}
    />
  );
};

export default Popup;
