import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { setIframeHeight } from './Utils';
import PublicFacingSite from './PublicFacingSite';
import { Provider } from 'react-redux';
import { createPublicFacingStore } from './rootStore/publicFacingStore';

// Only importing the admin site so that we bring it into the public-facing (when logged into admin) bundle. In the
// non-logged in public-facing bundle it will be ignored.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import AdminSite from './Dashboard/src/App';

declare global {
  interface Window {
    site?: {
      instantContactForm?: {
        display?: boolean;
        formUrl: string;
        popupUrl: string;
        pageId: string;
        text: string;
        styleId: string;
      };
    };
  }
}

// Legacy handler for setting iframe height, doesn't work cross-origin. Everything new should use postMessage.
window.setIframeHeight = setIframeHeight;

window.addEventListener('message', (message: MessageEvent) => {
  if (!message.isTrusted) return;

  const {
    iframeId,
    iframeLocation,
    iframeHeight,
  } = message.data as {
    iframeId?: string;
    iframeLocation?: string;
    iframeHeight?: number;
  };

  if (!iframeLocation || typeof iframeHeight !== 'number') return;

  document.querySelectorAll<HTMLIFrameElement>('iframe').forEach(iframe => {
    if (iframe.src === iframeLocation || iframe.dataset.frameid == iframeId) {
      iframe.style.height = `${iframeHeight + 1}px`;
    }
  });
});
const App: React.FC = () => (
  <StrictMode>
    <Provider store={createPublicFacingStore()}>
      <PublicFacingSite />
    </Provider>
  </StrictMode>
);

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
