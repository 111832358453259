import React, { useEffect } from 'react';

interface IframeMessage {
  iframeId?: string;
  iframeLocation: string;
  iframeHeight: number;
}

const SetIframeHeight: React.FC = () => {
  useEffect(() => {
    if (window === window.top) {
      return;
    }

    const setIframeHeight = (): void => {
      const frameElement = window.frameElement as HTMLIFrameElement | null;
      const message: IframeMessage = {
        iframeId: frameElement?.dataset?.frameid,
        iframeLocation: window.location.href,
        iframeHeight: document.body.clientHeight,
      };

      top?.postMessage(message, '*');
    };

    setIframeHeight();

    // And run it every so often just in case things inside the iframe get resized.
    const intervalId = setInterval(setIframeHeight, 250);

    return () => clearInterval(intervalId);
  }, []);

  return null;
};

export default SetIframeHeight;
