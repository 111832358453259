import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'ts/interfaces';
import '../../stylesheets/back_to_top.css';

const BackToTop: React.FC = () => {
  // Don't want to show the button inside iframes.
  if (typeof window !== 'undefined' && window !== window.top) {
    return <></>;
  }

  const page = useSelector((state: RootState) => state.pageData.page);

  if (!page.back_to_top) {
    return <></>;
  }

  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    let scrollPos = 0;
    let ticking = false;

    const handleScroll = () => {
      scrollPos = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          setHidden(scrollPos <= 500);
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <a
      href="#top"
      id="back-to-top"
      className={`top-link ${hidden ? 'hide' : ''}`}
    >
      <i className="fa fa-chevron-up back-to-top-icon" />
    </a>
  );
};

export default BackToTop;
