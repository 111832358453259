import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStore } from '../rootStore';
import { RootState, PageData } from 'ts/interfaces';
import CustomCode from 'common/models/CustomCode';

function AddCustomCode(): null {
  const store = createStore();
  const [readyCodeRun, setReadyCodeRun] = useState<boolean>(false);
  const [loadCodeRun, setLoadCodeRun] = useState<boolean>(false);
  const { pageData: { custom_codes: customCodes } } = store.getState() as { pageData: PageData };
  const templateLoaded = useSelector((state: RootState) => state.pageData.template_loaded);

  useEffect(() => {
    const jsCodes = customCodes.filter(
      (item: CustomCode) => item.language === 'javascript',
    );
    jsCodes.forEach((custom_code: CustomCode) => {
      if (!custom_code.code) {
        return;
      }

      if (custom_code.code_position === 'ready' && templateLoaded && !readyCodeRun) {
        (new Function(custom_code.code))();
        document.dispatchEvent(new Event('render'));
        setReadyCodeRun(true);
      } else if (custom_code.code_position === 'load' && !loadCodeRun) {
        setLoadCodeRun(true);
        window.addEventListener('load', () => {
          if (!custom_code.code) {
            return;
          }

          if (!loadCodeRun) {
            (new Function(custom_code.code))();
            document.dispatchEvent(new Event('render'));
            setLoadCodeRun(true);
          }
        });
      }
    });
  }, [customCodes, templateLoaded, readyCodeRun, loadCodeRun]);

  return null;
}

export default AddCustomCode;
