import { useEffect } from 'react';
import { useAppSelector } from '../rootStore';
import { useUserConditional } from 'common/hooks';

const hitId = document.querySelector('meta[name="wg:hitId"]')?.getAttribute('content');
const server = document.querySelector('meta[name="wg:server"]')?.getAttribute('content');
const cached = document.querySelector('meta[name="wg:cached"]')?.getAttribute('content');

const AddWebVitalsTracking: React.FC = () => {
  const instance = useAppSelector((state) => state.pageData.instance);
  const site = useAppSelector((state) => state.pageData.site);
  const adminDomain = useAppSelector((state) => state.pageData.admin_domain);
  const user = useUserConditional();

  useEffect(() => {
    // We don't want to add Web Vitals tracking if the user is logged into admin. This is because it's not
    // representative of the real world for a few reasons -- the JS bundle is bigger, we reload the page if the
    // wg_admin cookie is not set, and the page loads are more likely to be uncached because the user is probably
    // working on the site.
    if (user !== false) {
      return;
    }

    window.addEventListener('load', () => {
      if (document.getElementById('webVitals')) {
        return;
      }

      const script = document.createElement('script');
      script.id = 'webVitals';
      script.src = 'https://cdn.jsdelivr.net/npm/web-vitals@3.5.2/dist/web-vitals.attribution.iife.min.js';
      script.onload = () => {
        (window as any).webVitals.onCLS(sendToServer);
        (window as any).webVitals.onFID(sendToServer);
        (window as any).webVitals.onLCP(sendToServer);
        (window as any).webVitals.onTTFB(sendToServer);
        (window as any).webVitals.onFCP(sendToServer);
        (window as any).webVitals.onINP(sendToServer);
      };

      document.head.appendChild(script);
    });

    if (typeof cached !== 'undefined') {
      sendToServer({ name: 'cached', value: cached === 'true' });
    }
    if (typeof server !== 'undefined') {
      sendToServer({ name: 'server', value: server });
    }
  }, [user]);

  const sendToServer = (metric: { name: any; value: any }) => {
    const body = JSON.stringify({
      hit_id: hitId,
      metric: metric.name,
      value: metric.value,
    });
    const headers = {
      type: 'application/json',
    };
    const url = `https://${adminDomain}/api/v1/instances/${instance.id}/sites/${site.id}/web_vitals`;
    const blob = new Blob([JSON.stringify(body)], headers);
    if (navigator.sendBeacon) {
      navigator.sendBeacon(url, blob);
    } else {
      fetch(url, {
        body,
        method: 'POST',
        keepalive: true,
        headers: headers,
      });
    }
  };

  return null;
};

export default AddWebVitalsTracking;
