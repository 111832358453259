import React from 'react';
import { useAppSelector } from '../rootStore';
import { PageDataObject } from '../ts/interfaces';
import { BlogPostType } from 'common/models/Site';

function BlogPost({ post }: { post: BlogPostType }) {
  const site = useAppSelector((state: PageDataObject) => state.pageData.site);
  const images = useAppSelector((state: PageDataObject) => state.pageData.file);
  const image = images.find((img) => img.id === post.blog_post_image_id);

  const author = post.blog_author;
  const date = post.blog_publish_date ? new Date(post.blog_publish_date) : null;

  return (
    <div
      key={post.id}
      className="blog-post"
    >
      <a href={post.url}>
        {post.blog_post_image_url && (
          <img
            src={post.blog_post_image_url}
            alt={post.title}
            style={{
              aspectRatio: image ? `${image.width}/${image.height}` : 'auto',
            }}
          />
        )}
        {!post.blog_post_image_url && (
          <div className="image-placeholder" />
        )}
        <h2 className={`style-${site.blog_post_title_style_id}`}>
          {post.title}
        </h2>
        <p>
          {author && (
            <span className="author">
              {author}
            </span>
          )}
          {author && date && (
            <span className="separator">|</span>
          )}
          {date && (
            <span className="date">
              {date.toLocaleString('en-US', { month: 'short' })}
              {' '}
              {date.getDate()}
              {' '}
              {date.getFullYear()}
            </span>
          )}
        </p>
        <div className={`style-${site.blog_body_style_id}`}>
          <div className="text-container">
            <p>{post.blog_excerpt}</p>
            <p className="read-more">
              Read more
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}

function BlogListLayoutOneColWithSidebar() {
  const site = useAppSelector((state: PageDataObject) => state.pageData.site);
  const blogPosts = useAppSelector((state: PageDataObject) => state.pageData.blog_posts);
  const blogTags = useAppSelector((state: PageDataObject) => state.pageData.blog_tags);

  const params = new URLSearchParams(location.search);
  const tag = params.get('tag');

  return (
    <>
      <div className="action-page">
        <style>
          {`
            @scope {
              .blog-post-list {
                img {
                  max-width: 100%;
                  margin-bottom: 1rem;
                }
              }

              .read-more {
                padding-bottom: 2rem;
              }

              .sidebar {
                position: sticky;
                top: 0;
                height: fit-content;
              }
            }
          `}
        </style>
        <div className="blog-post-list-container">
          <div className="row">
            <div className="col-md-9">
              {tag && (
                <h1 className={`style-${site.blog_post_title_style_id}`}>
                  Posts tagged with &quot;
                  {tag}
                  &quot;
                </h1>
              )}
              <div className="blog-post-list">
                {blogPosts.map((post) => <BlogPost key={post.id} post={post as BlogPostType} />)}
              </div>
            </div>
            <div className="sidebar col-md-3">
              <div className={`style-${site.blog_body_style_id}`}>
                <h2 className={`style-${site.blog_post_title_style_id}`}>Blog Tags</h2>
                <ul>
                  {blogTags.map((tag) => (
                    <li key={tag[0]}>
                      <a href={`${site.blog_page_url}?tag=${encodeURIComponent(tag[0])}`}>
                        {tag[0]}
                        {' '}
                        (
                        {tag[1]}
                        )
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogListLayoutOneColWithSidebar;
