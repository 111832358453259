import React from 'react';
import { createStore, useAppDispatch, useAppSelector } from 'rootStore';
import { sectionObject, getActionComponentFromString } from '../Utils';
import { setClickedSection } from 'common/api/clickedSectionSlice';
import Blog from './Blog';
import BlogPostLayout1 from './BlogPostLayout1';
import { PageDataObject, ActionComponentMap } from 'ts/interfaces';
import Section from 'common/models/Section';
import SectorModel from 'common/models/Sector';

interface SectorProps {
  sector: SectorModel;
}

const Sector: React.FC<SectorProps> = ({ sector }) => {
  const store = createStore();
  const dispatch = useAppDispatch();
  const {
    pageData: {
      precompiled_html: precompiledHTML,
      master_page: masterPage,
      page_version: pageVersion,
      site,
      page,
    },
  } = store.getState();
  const allSections = useAppSelector((state: PageDataObject) => state.pageData.section);
  const sections = allSections.filter((s) => s.sector === sector.label && !s.column_section);

  if (precompiledHTML && sector.label && precompiledHTML[sector.label]) {
    return (
      <div
        className="precompiled-html"
        dangerouslySetInnerHTML={{ __html: precompiledHTML[sector.label] }}
      />
    );
  }

  const sectionComponentFromSectionObject = (section: Section) => (
    sectionObject(page, section, (clickedSection: any) => dispatch(setClickedSection(clickedSection)))
  );

  let Action: React.ComponentType | null | undefined = null;
  const params = new URL(document.location.href).searchParams;
  if (params.get('Action')) {
    Action = getActionComponentFromString(params.get('Action') as keyof ActionComponentMap);
  }

  const blogPostLayoutMap = {
    layout_1: BlogPostLayout1,
    // layout_2: BlogPostLayout2,
  };

  const BlogPostLayout = blogPostLayoutMap[site.blog_post_layout] || BlogPostLayout1;

  const getTopSections = (): React.ReactNode[] => {
    if (sector.is_primary && page.page_type === 'blog') {
      return [<BlogPostLayout key="BlogPostLayoutTop" sector={sector} where="top" />];
    } else {
      return [];
    }
  };

  const getBottomSections = (): React.ReactNode[] => {
    if (sector.is_primary && page.page_type === 'blog') {
      return [<BlogPostLayout key="BlogPostLayoutBottom" sector={sector} where="bottom" />];
    } else {
      return [];
    }
  };

  const getCMSSections = (): React.ReactNode[] => {
    if (Action) {
      return [];
    }

    // These are the sections from the CMS that are associated with this sector.
    return sections
      .filter((s) => !s.is_offline && s.page_version_id === pageVersion.id)
      .map(sectionComponentFromSectionObject)
    ;
  };

  const getMasterSections = (): React.ReactNode[] => {
    if (!masterPage) {
      return [];
    }

    return sections
      .filter((s) => !s.is_offline && s.page_version_id === masterPage.published_version_id)
      .map(sectionComponentFromSectionObject)
    ;
  };

  const getActionSections = (): React.ReactNode[] => {
    if (!sector.is_primary) {
      return [];
    }

    if (site.blog_page_id && page.id === site.blog_page_id) {
      return [<Blog key="Blog" />];
    }

    if (Action) {
      return [<Action key="Action" />];
    }

    return [];
  };

  if (sector.master_content_position === 'top') {
    return (
      <>
        {[
          ...getMasterSections(),
          ...getTopSections(),
          ...getActionSections(),
          ...getCMSSections(),
          ...getBottomSections(),
        ]}
      </>
    );
  } else {
    return (
      <>
        {[
          ...getTopSections(),
          ...getCMSSections(),
          ...getActionSections(),
          ...getBottomSections(),
          ...getMasterSections(),
        ]}
      </>
    );
  }
};

export default Sector;
